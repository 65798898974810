import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './IndexPopup.module.scss'
import {Board} from "../../../../graphql/types";
import moment from "moment-timezone";

const cx = classNamesBind.bind(styles);

export const IndexPopup = (props: {
    value: Board[],
}) => {
    const [idx, setIdx] = useState(0);
    const [view, setView] = useState(true);

    useEffect(() => {
        setIdx(props.value.length - 1);
    }, [props.value])

    const handler = {
        setStorage: (name: string, exp: string) => {
            localStorage.setItem(name, exp);
        },
        getStorage: (name: string) => {
            const parseDate = localStorage.getItem(name) || "";
            const nowDate = moment().format("YYYY-MM-DD");
            if (parseDate !== "") {
                return !moment(parseDate).isSame(nowDate);
            }
            return true;
        },
    }

    const data = props.value[idx];

    const onCountDown = () => {
        handler.setStorage(data.id.toString(), moment().tz("Asia/Seoul").format("YYYY-MM-DD"));
        const newIdx = idx - 1;
        if (0 <= newIdx) {
            setIdx(newIdx);
        } else {
            setView(false);
        }
    }

    return (
        <Fragment>
            {view && data && handler.getStorage(data.id.toString()) &&
                <div className={cx('cont')}>
                    <div className={cx('box')}>
                        <div className={cx("relative")}>
                            <div className={cx('contents')}
                                 style={{background: data.bgColor}}
                                 ref={ref => {
                                     if (ref) {
                                         ref.innerHTML = data.content;
                                     }
                                 }}/>

                            <div className={cx('footer')}>
                                <button
                                    className='btn btn-sm'
                                    style={{width: "90%", backgroundColor: "#000000", color: "white"}}
                                    onClick={onCountDown}>24시간 동안 보지 않기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}
